<template>
  <div class="my">
    <h1>个人中心</h1>
    <el-form label-width="auto">
      <el-form-item>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
          <el-button type="info" @click="resetPassword()">重置密码</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-avatar :size="200" :src="require('../../assets/logo.png')" style="margin: auto;" />
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="data.userInfo.nick" />
      </el-form-item>
      <el-form-item label="电话号">
        <el-input v-model="data.userInfo.phone" />
      </el-form-item>
      <el-form-item label="角色">
        <el-input v-model="data.userInfo.roleName" disabled />
      </el-form-item>
      <el-form-item label="特殊身份" v-if="data.userInfo.admin">
        <el-tag type="primary">超管</el-tag>
      </el-form-item>
    </el-form>
  </div>

  <el-dialog v-model="showData.resetPasswordShow" title="新密码：" width="550" :close-on-click-modal="false" :close-on-press-escape="false">
    <p>
      <el-text>为了您的密码安全，</el-text>
      <el-text size="large"><b>新密码只展示一次</b></el-text>
      <el-text>，请注意保管。请将密码保存在本地，不要保存在网盘、Git，请不要在聊天软件、网络社区平台转发分享。</el-text>
    </p>
    <p>
      <el-link type="primary" @click="util.clipBoard(showData.resetPasswordValue)">
        {{ showData.resetPasswordValue }}
      </el-link>
    </p>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showData.resetPasswordShow = false">好的</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()

const data = reactive({
  userInfo: {}
})

const showData = reactive({
  resetPasswordShow: false,
  resetPasswordValue: ''
})

api.get('/backend/user/getMyInfo').then(res => {
  data.userInfo = res
})

const save = () => {
  api.post('/backend/user/setMy', {
    nick: data.userInfo.nick,
    phone: data.userInfo.phone
  }).then(() => {
    const loginUser = cache.getObject(cache.keys.loginUser)
    loginUser.nick = data.userInfo.nick
    loginUser.phone = data.userInfo.phone
    cache.setObject(cache.keys.loginUser, loginUser)
    ElMessage.success('修改成功')
  })
}

const resetPassword = () => {
  ElMessageBox.confirm('', {
    title: '确认重置密码',
    message: '将要重置您的密码。',
    autofocus: false,
    confirmButtonText: '重置',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/user/resetMyPassword').then(res => {
      ElMessage.success('重置密码成功')
      setTimeout(() => {
        showData.resetPasswordValue = res.password
        showData.resetPasswordShow = true
      }, 1000)
    })
  })
}
</script>

<style lang="less">
.my {
  margin: auto;
  max-width: 660px;
}
</style>